import { FC } from 'react'
import styled from 'styled-components'
import { Goal } from '@netpurpose/api'
import { Icon, IconName, ThemeColor } from '@netpurpose/np-ui'
import { DataValue, isNumber } from '@netpurpose/types'

const ValueContainer = styled.div<{ $color: ThemeColor }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.25rem;
  color: ${({ theme, $color }) => theme.colors[$color]};
`

export const getIconProps = (
  value: number | undefined,
  goal: Goal,
): { color: ThemeColor; icon: IconName; alt: string } => {
  if (goal === Goal.NEUTRAL || value === 0) {
    return { color: 'typeSecondary', icon: 'Dash', alt: 'Neutral' }
  }
  const isIncrease = value && Boolean(value > 0)
  const showGreen =
    (goal === Goal.INCREASE && isIncrease) || (goal === Goal.DECREASE && !isIncrease)

  if (showGreen) {
    return {
      color: 'goalGreen',
      icon: 'DoubleChevronRight',
      alt: 'Positive',
    }
  }
  return {
    color: 'goalRed',
    icon: 'DoubleChevronLeft',
    alt: 'Negative',
  }
}

type Props = {
  dataValue: DataValue
  goal: Goal
  isAbsoluteYoyValue?: boolean
  children: React.ReactNode
}

export const YoYValue: FC<Props> = ({ dataValue, goal, isAbsoluteYoyValue, children }) => {
  if ((!isAbsoluteYoyValue && dataValue.unit !== 'ratio') || !isNumber(dataValue.value)) {
    return children
  }

  return (
    <ValueContainer $color={getIconProps(dataValue.value, goal).color}>
      {children}
      <Icon
        viewbox="0 0 48 48"
        width={16}
        height={16}
        {...getIconProps(dataValue.value, goal)}
        hideTooltip
      />
    </ValueContainer>
  )
}
