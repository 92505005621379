import { FC } from 'react'
import styled from 'styled-components'
import { Goal } from '@netpurpose/api'
import { DataValue, FactoidOrigin, isNumber, NOT_APPLICABLE, NOT_FOUND } from '@netpurpose/types'
import { Monospaced } from '#components/common/dataRendering'
import { Factoid } from '#components/company/CompanyData/Factoid'
import { DataChallengeDropdown } from '#components/feedback/DataChallengeDropdown'
import { KPIChallengeType, KPIDataType } from '#components/feedback/feedbackTypes'
import { getRenderDataValue } from '#services/dataRendering'
import {
  formatImpact,
  formatImpactPerMillion,
  formatImpactWithDynamicPrecision,
} from '#services/dataRendering/formatters'
import { YoYValue } from './YoYValue'

const NotApplicableText = styled(Monospaced)`
  color: ${({ theme }) => theme.colors.typeTertiary};
`

type Props = {
  dataValue: DataValue
  questionId: number | undefined
  questionName: string
  isNd: boolean | undefined
  collectionType: KPIChallengeType
  collectionName: string
  kpiDataType: KPIDataType
  factoidOrigin?: FactoidOrigin
  entityId?: number
  ExtraWrapper?: ({ children }: { children: React.ReactNode }) => React.ReactNode
  ComparisonValue?: () => React.ReactNode
  goal?: Goal | undefined
  formatWithDynamicPrecision?: boolean
}

export const ImpactValue = ({
  dataValue,
  questionId,
  questionName,
  isNd,
  collectionType,
  collectionName,
  kpiDataType,
  factoidOrigin,
  entityId,
  ExtraWrapper = ({ children }) => <>{children}</>,
  ComparisonValue,
  goal,
  formatWithDynamicPrecision,
}: Props) => {
  const getFormatter = () => {
    if (formatWithDynamicPrecision) {
      return formatImpactWithDynamicPrecision
    }
    return kpiDataType === 'Intensity' ? formatImpactPerMillion : formatImpact
  }

  const renderDatapoint = getRenderDataValue(getFormatter())
  const renderedValue = renderDatapoint(dataValue)

  const formattedValue = isNumber(dataValue.value)
    ? formatImpact(dataValue.value, dataValue.unit)
    : dataValue.value

  if (!isNd && dataValue.unit === NOT_FOUND) {
    return <NotApplicableText>{NOT_APPLICABLE}</NotApplicableText>
  }

  // Only applies to companies, not portfolios.
  if (!isNd && factoidOrigin) {
    return (
      <Factoid
        factoidOrigin={factoidOrigin}
        formattedValue={formattedValue}
        entityId={entityId}
        questionId={questionId}
        questionName={questionName}
        entityName={collectionName}
      />
    )
  }

  const ValueToRender = () => {
    if (ComparisonValue) {
      return <ComparisonValue />
    }
    if (goal) {
      return (
        <YoYValue dataValue={dataValue} goal={goal}>
          {renderedValue}
        </YoYValue>
      )
    }
    return renderedValue
  }

  const Wrapper: FC<{ children: React.ReactNode }> = ({ children }) =>
    questionId ? (
      <DataChallengeDropdown
        type={collectionType}
        id={questionId}
        value={renderedValue}
        kpiName={questionName}
        kpiType={kpiDataType}
        collectionName={collectionName}
      >
        {children}
      </DataChallengeDropdown>
    ) : (
      'span'
    )

  return (
    <ExtraWrapper>
      <Wrapper>
        <ValueToRender />
      </Wrapper>
    </ExtraWrapper>
  )
}
