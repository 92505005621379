import { FC } from 'react'
import { FactoidOrigin, isStringArray } from '@netpurpose/types'
import { Monospaced } from '#components/common/dataRendering'
import { Estimated, Reported, Standardised } from './FactValueComponents'

type Props = {
  factoidOrigin: FactoidOrigin | undefined
  formattedValue: string | undefined
  entityId: number | undefined
  questionId: number | undefined
  questionName: string | undefined
  entityName: string
  withEstimatedIcon?: boolean
}

export const Factoid: FC<Props> = ({
  factoidOrigin,
  formattedValue,
  entityId,
  questionId,
  questionName,
  entityName,
  withEstimatedIcon = true,
}) => {
  if (factoidOrigin?.dataType === 'Standardised' && typeof factoidOrigin?.factoidId === 'string') {
    return (
      <Standardised
        kpiResultId={factoidOrigin.factoidId}
        value={formattedValue}
        entityId={entityId}
      />
    )
  }
  if (
    factoidOrigin?.dataType === 'Reported' &&
    typeof factoidOrigin?.factoidId === 'number' &&
    entityId &&
    questionId &&
    questionName
  ) {
    return (
      <Reported
        factId={factoidOrigin.factoidId}
        value={formattedValue}
        questionName={questionName}
        entityId={entityId}
        questionId={questionId}
        entityName={entityName}
      />
    )
  }
  if (
    factoidOrigin?.dataType === 'Estimated' &&
    isStringArray(factoidOrigin?.factoidId) &&
    entityId &&
    questionName &&
    questionId &&
    formattedValue
  ) {
    return (
      <Estimated
        value={formattedValue}
        entityId={entityId}
        technologyIds={factoidOrigin?.factoidId}
        year={factoidOrigin?.year}
        questionId={questionId}
        questionName={questionName}
        withIcon={withEstimatedIcon}
      />
    )
  }
  return <Monospaced>{formattedValue}</Monospaced>
}
