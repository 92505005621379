import { isNumber, PERCENTAGE, RATIO_UNIT } from '@netpurpose/types'
import { formatNumberWithThousandGroups, formatRatioAsPercent } from '@netpurpose/utils'

const getPrecision = (value: number): number => {
  if (value >= 10000 || value === 0) {
    return 0
  }
  return 2
}

export const formatImpactWithDynamicPrecision = (
  value?: number | null,
  metricUnit?: string,
  placeholderValue?: string,
) => {
  const precision = isNumber(value) ? getPrecision(value) : 0

  return metricUnit === RATIO_UNIT || metricUnit === PERCENTAGE
    ? formatRatioAsPercent(value, placeholderValue)
    : formatNumberWithThousandGroups(value, precision, placeholderValue)
}
