import { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 0.75rem;
  div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
  }
`
const Title = styled.h5`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.typeSecondary};
`

const ContentSection = styled.div`
  padding: 1rem;
`

export type TooltipContentType = { title?: string; text: string }[]

export const TooltipContent: FC<{ content: TooltipContentType }> = ({ content }) => (
  <Container>
    {content.map((item: TooltipContentType[number]) => (
      <ContentSection key={`${item.title}-${item.text}`}>
        {item?.title && <Title>{item.title.toUpperCase()}</Title>}
        <div>{item.text}</div>
      </ContentSection>
    ))}
  </Container>
)
