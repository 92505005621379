import { NextRouter, useRouter } from 'next/router'
import { config } from '#services/config'

export const getBreadcrumbs = (pathname: string) => {
  if (pathname.includes('explore')) {
    return [{ name: 'Explore', href: config.routes.explore }, { name: 'Compare' }]
  }

  return [{ name: 'Portfolios', href: config.routes.portfolioList }, { name: 'Compare' }]
}

export const getQueryParamsAsObjectArray = (query: ReturnType<typeof useRouter>['query']) => {
  const values = Object.keys(query)
    // Get all ids => [id_1, id_2, ...]
    .filter((key) => key.startsWith('id_'))
    .map((key) => ({
      // Get value of id_N
      id: query[key] as string,
      // For id_N get value of type_N
      type: query[key.replace('id_', 'type_')] as string,
    }))

  return values
}

export const setQueryParamsFromObjectArray = (
  values: { id: string; type: string }[],
  router: NextRouter,
) => {
  const query = values.reduce((acc, { id, type }, index) => {
    return {
      ...acc,
      [`id_${index + 1}`]: id,
      [`type_${index + 1}`]: type,
    }
  }, {})

  router.replace({ query }, undefined, { shallow: true })
}

export const convertObjectArrayToCompareQueryParams = (values: { id: string; type: string }[]) => {
  const query = values.reduce((acc, { id, type }, index) => {
    return {
      ...acc,
      [`id_${index + 1}`]: id,
      [`type_${index + 1}`]: type,
    }
  }, {})

  const queryString = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

  return queryString
}
