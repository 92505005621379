import styled from 'styled-components'
import { Goal } from '@netpurpose/api'
import { ComparisonSpot, lightTheme as theme } from '@netpurpose/np-ui'
import {
  MetricsTabContent,
  TextListSection,
  TextListTabContent,
} from '#components/common/SidePanel/components'

const ColouredSquare = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const keyContent: TextListSection[] = [
  {
    key: '1',
    sectionTitle: 'COMPARE',
    items: [
      {
        title: 'Best in Class',
        icon: (
          <ColouredSquare $color={`${theme.colors.semanticPositive}20`}>
            <ComparisonSpot tooltipText="" goal={Goal.INCREASE} isIncrease />
          </ColouredSquare>
        ),
        text: 'The best performing of the companies and portfolios selected to compare.',
      },
      {
        title: 'Neutral',
        icon: (
          <ColouredSquare $color={theme.colors.bgPrimary}>
            <ComparisonSpot tooltipText="" goal={Goal.NEUTRAL} isIncrease />
          </ColouredSquare>
        ),
        text: 'Neither the best nor the worst performing of the companies and portfolios selected to compare.',
      },
      {
        title: 'Worst in Class',
        icon: (
          <ColouredSquare $color={`${theme.colors.semanticNegative}25`}>
            <ComparisonSpot tooltipText="" goal={Goal.DECREASE} isIncrease />
          </ColouredSquare>
        ),
        text: 'The worst performing of the companies and portfolios selected to compare.',
      },
    ],
  },
]

export const getSidePanelItems = ({ questionIds }: { questionIds: number[] }) => [
  {
    label: 'Legend',
    key: 'Legend',
    children: <TextListTabContent content={keyContent} />,
  },
  {
    label: 'Metrics',
    key: 'Metrics',
    children: <MetricsTabContent questionIds={questionIds} />,
  },
]
